import * as React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';

import '../assets/css/espaceAngouleme.scss'
import NavbarLayout from '../components/navbarLayout'
import FooterLayout from '../components/footerLayout'
import mockup from '../assets/img/musee_angouleme_salle.jpg'
import fleche from '../assets/img/flecheVersLeBas.png'
import { Link } from 'gatsby'

const AngoulemePage = () => {
  return (
    <body>

      <header className="header_musee">
        <NavbarLayout> </NavbarLayout>
        <div className="contenu">
          <h2>Angoulême</h2>
          <div className="buttonHeader">
            <a className="blue_button js-modal-btn"> Kit pédagogique </a>
          </div>
        </div>
        <div className="scrollButton">
          <a onClick={() => scrollTo('#jeux')}><img  src={fleche}/></a>
        </div>
        
      </header>
      <section className="jeux" id="jeux">
        <h2>Déroulement du Serious Game</h2>  
        <div className="introJeux">
          <p>Le Musée d'Angoulême abrite trois collections dont deux d'importance internationale. D'une part, la collection d'art d'Afrique et d'Océanie et d'autre part, la collection archéologique qui permet d'évoquer l'histoire charentaise, du temps des dinosaures jusqu'à l'époque médiévale. Quant à la collection beaux-arts, elle permet de présenter un échantillonnage de belles peintures européennes du XVIIème au XIXème siècle, ainsi qu'un fonds important de peintures et céramiques charentaises des XIXème et début du XXème siècle.</p>
          <div className="imageIntro">
            <div className="imageHorizontale">
              <img src={mockup} />
              <img src={mockup} />
            </div>
            <div className="imageVerticale">
              <img src={mockup} />
              <img src={mockup} />
            </div>
          </div>
        </div>
        <div className="chronologie">
          <p>Les élèves seront alors initiés à la culture muséal en trois temps :</p>
          <span></span>
          <div className="introChronologie">
            <div className="items">
              <h3>Avant la visite</h3>
              <p>Expliquer le fonctionnement d'un musée ainsi que l'intéret d'y aller tout en préparant l'immersion dans l'histoire contée.</p>
            </div>
            <div className="items">
              <h3>Pendant la visite</h3>
              <p>Les enfants participent en groupes à l'histoire et tentent de la résoudre.</p>
            </div>
            <div className="items">
              <h3>Après la visite</h3>
              <p>Faire un bilan des compétences acquises.</p>
            </div>
          </div>
        </div>
        <p>Cette initiative aura plusieurs objectif:</p>
        <ul>
          <li>Etablir une relation privilégiée avec les objets exposés</li>
          <li>Travailler des compétences collaboratives</li>
          <li>Proposer un appart de connaissances de façon ludique</li>
        </ul>
        <p>Cette activité pédagogique est destinée à des élèves du 3e cycle(CM1, CM2 et sixième)</p>
      </section>
      <section className="temoignage">

      </section>
      <section className="information">
        <h2>Informations pratiques</h2>
        <div className="contenuInfo">
          <div className="acces">
            <h3>Accès</h3>
            <p>Bus de ville (STGA) Arrêt Cathédrale<br />Lignes A - B - 1 - 3 - 5 - 7</p>
            <span className="pointDecoration"></span>
            <p>Possibilité de réservation de Bus <br />pour votre classe</p>
            <span className="pointDecoration"></span>
            <p>Entrée du public <br />Square Girard II (rue Corneille)</p>
          </div>
          <div className="contact">
            <h3>Contact</h3>
            <p><h4>Services des publics</h4>05 45 95 79 89</p>
            <span className="pointDecoration"></span>
            <p><h4>Site Internet</h4><a href="http://www.musee-angouleme.fr">http://www.musee-angouleme.fr</a></p>
            <span className="pointDecoration"></span>
            <p><h4>Mail</h4>mediation_musee@mairie-angouleme.fr</p>
          </div>
        </div>
      </section>

      <FooterLayout></FooterLayout>
    </body>
  )
}

export default AngoulemePage
