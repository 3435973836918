import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useState,useEffect } from "react"
import '../assets/css/reset.css'
import '../assets/css/style.scss'


const NavbarLayout = () => {
  const [navOpacity,setnavOpacity] = useState("1");
  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#2d200e") : setnavColor("transparent");
    window.scrollY > 10 ? setnavOpacity("0.7") : setnavOpacity("1")
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <nav 
    style={{
      backgroundColor: navColor,
      opacity: navOpacity,
      transition: "all 1s"
    }}>
      <StaticImage src="../assets/img/logo.png" alt="Les aventures de Rose" height={80}/>
      <ul>
        <li><Link to="/">Accueil</Link></li>
        <li className="deroulant">
          <Link to="/musees">Les Musées</Link>
          <ul className="sous" style={{
                  backgroundColor: navColor,
                  opacity: navOpacity,
          }}>
            <li > <Link to="/espaceAngouleme">Angouleme</Link> </li>
            <li> <Link to="/espaceLaRochelle">La Rochelle</Link> </li>
          </ul>
        </li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  )
}


export default NavbarLayout
